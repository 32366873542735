html {
    height: 100vh;
}

body {
    height: 100%;
}

li {
    color: white;
}

a {
    color: #3E4095;
    text-decoration: none;
    font-size: x-large;
    transition: background-color .3s;
    cursor: pointer;
}

a:hover {
    background-color: #527510;
    color: black;
}

button {
    background-color: #168C8C;
    font-weight: bold;
    color: white;
}

textarea {
    resize: none;
}

#barra {
    background-color: #dad7cd;
    height: 100vh;
    padding-top: 3%;
}
.modal-backdrop {
    z-index: 1040 !important;
}
.modal-content {
    margin: 2px auto;
    z-index: 1100 !important;
}